.campaign-image {
    height: 16rem;
    overflow: hidden;
    position: relative;
}

.campaign-page {
    position: relative;
    min-height: 100vh;

    background: var(--white);
}

.campaign-page-bouton-retour {
    /* border: 1px solid black; */
    z-index: 100;

    position: absolute;
    top: 2rem;
    left: 2rem;
    margin-bottom: -4.8rem;
}

.campaign-image>img {
    object-fit: cover;
    filter: blur(4px);
}

.campaign-infos {
    display: flex;
    margin-top: -3.5rem;
}

.campaign-infos-title {
    display: flex;
    position: relative;
    margin-left: 5rem;
    color: var(--white);
    align-items: center;
    font-size: 2.9rem;
    font-weight: 700;
    font-family: var(--font-family);
    gap: 1rem;

    /* border: 1px solid black; */
}

.campaign-infos-title>img {
    width: 3rem;
    height: 3rem;
}

.campaign-infos-container {
    display: flex;
    flex-direction: column;
}

.campaign-desc {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* border: 1px solid black; */
}

.campaign-desc>p {
    max-width: 70%;
    font-size: 1.625rem;
    font-family: var(--font-family);
    text-align: center;
    color: var(--color-secondary);
    margin-top: 6.5rem;
}

.campaign-prizes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* width: 100%; */
    margin-top: 8rem;
    padding: 1.5rem 3rem;

    background: var(--black-05);
}

.campaign-prizes-left,
.campaign-prizes-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--color-secondary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    gap: 1rem;

    transition: all 0.1s ease-in-out;
}

.campaign-page__logo-csleague {
    height: 4rem;
}

.campaign-prizes-left img,
.campaign-prizes-right img {
    height: 4rem;
    border-radius: 4px;
    overflow: hidden;
}

.campaign-prizes-left:hover,
.campaign-prizes-right:hover {
    transform: scale(1.05);
}

.a-vos-marques {
    margin-top: 8rem;

    color: var(--color-primary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.campaign-page-bets {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    gap: 2rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
    flex-direction: column;
}

.campaign-page-bets-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.campaign-page-grid {
    display: flex;

    border: 1px solid black;
}

















@media screen and (max-width:1150px) {

    .campaign-page-bets-row,
    .campaign-page-bets {
        gap: 1rem;
    }
}

@media screen and (max-width:925px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 1rem;
        gap: 0.2rem;
    }

    .campaign-prizes-left img,
    .campaign-prizes-right img,
    .campaign-page__logo-csleague {
        height: 2.25rem;
    }

    .campaign-prizes-left:hover,
    .campaign-prizes-right:hover {
        transform: scale(1);
    }
    
}

@media screen and (max-width:768px) {
    .campaign-desc>p {
        font-size: 1.25rem;
    }

    .a-vos-marques {
        font-size: 1.6rem;
        margin-top: 6rem;
    }
}

@media screen and (max-width:580px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 0.8rem;
        gap: 0.2rem;
    }

    .campaign-prizes-left img,
    .campaign-prizes-right img,
    .campaign-page__logo-csleague {
        height: 2rem;
    }

    .campaign-prizes {
        padding: 1.5rem 1rem;
    }
}

@media screen and (max-width:500px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        gap: 0.1rem;
    }
}

@media screen and (max-width:425px) {
    .campaign-desc>p {
        max-width: 90%;
        font-size: 1rem;
    }

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 0rem;
        gap: 0.2rem;
    }

    .campaign-infos-title {
        top: 0.5rem;
        margin-left: 3rem;
        font-size: 2rem;
        gap: 0.5rem;
    }

    .campaign-infos-title>img {
        width: 2rem;
        height: 2rem;
    }

    .a-vos-marques {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:320px) {
    .campaign-prizes {
        padding: 1rem 0.5rem;
    }

    .campaign-infos-title {
        margin-left: 1.5rem;
    }
}