.auth-page {
    /* margin-top: 20rem; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-page-navbar {
    width: 100%;
}

.auth-page h1 {
    margin-top: 8rem;
    margin-bottom: 8rem;

    color: var(--color-secondary);
    text-align: center;

    /* Heading 2 Bold */
    font-family: var(--font-family);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    max-width: 70%;
}

.auth-page-title {
    color: var(--color-secondary);
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.auth-page-main {
    width: 100%;
    background-color: var(--black-05);

    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

}

.auth-page-mode-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.auth-page-footer {
    display: flex;
    width: 100%;
}
/* 
.auth-page-mode>img {
    max-width: 3rem;
    max-height: 3rem;
} */

/* .auth-page-mode {
    display: flex;
    font-family: var(--font-family);
    color: var(--color-secondary);
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: transparent;
}

.auth-page-mode:hover {
    background-color: var(--black-05);
    transition: all 0.2s ease-in-out;
} */

@media screen and (max-width:768px) {
    .auth-page h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width:425px) {
    .auth-page h1 {
        font-size: 1.25rem;
        max-width: 90%;
        width: 90%;
    }
    .auth-page-title {
        font-size: 1rem;
    }
    .auth-page-mode-container {
        flex-direction: column;
    }
}