.welcome-page {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.navbar-container {
    display: flex;
    width: 100%;
}

.welcome-page-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.welcome-page-main-name>p {
    white-space: nowrap;
    font-size: 5rem;
    font-weight: 700;
    font-family: var(--font-family);
    background: var(--main-gradient);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.header {
    margin-top: 15rem;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: 40%;

    /* margin-bottom: 15rem; */
}

.header h1 {
    text-align: center;

    color: var(--color-secondary);
    /* Heading 1 Bold */
    font-family: var(--font-family);
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header p {
    width: 80%;

    color: var(--color-secondary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.welcome-page-titled-separator {
    font-family: var(--font-family);
    font-size: 2rem;
    color: var(--color-secondary);
    font-weight: 700;
    padding-left: 6rem;

    margin-top: 21rem;
    margin-bottom: -21rem;
}

@media screen and (max-width:800px) {
    .header {
        width: 50%;
        margin-top: 12rem;
        margin-bottom: -6rem;
    }

    .header>h1 {
        font-size: 2rem;
    }

    .welcome-page-titled-separator {
        padding-left: 2rem;
        font-size: 1.8rem;
    }
}

@media screen and (max-width:426px) {
    .header {
        width: 80%;
        margin-top: 9rem;
        margin-bottom: -9rem;
    }

    .welcome-page-titled-separator {
        padding-left: 1.2rem;
        font-size: 1.4rem;
        margin-bottom: -20rem;
    }
}

@media screen and (max-width:321px) {
    .header>h1 {
        font-size: 1.6rem;
    }

    .header>p {
        font-size: 0.8rem;
    }
}