.bet-page {
    background-color: var(--white);
}

.campaign-image {
    height: 16rem;
    overflow: hidden;
    position: relative;
}

.campaign-image>img {
    object-fit: cover;
    filter: blur(4px);
    width:100%;
}

.bet-page-bouton-retour {
    /* border: 1px solid black; */
    z-index: 100;

    position: absolute;
    top: 2rem;
    left: 2rem;
    margin-bottom: -4.8rem;
}

.bet-page {
    position: relative;
}

.bet-infos {
    display: flex;
    margin-top: -6rem;

    /* border: 1px solid black; */
}

.bet-infos-title {
    display: flex;
    position: relative;
    margin-left: 5rem;
    color: var(--white);
    align-items: baseline;
    font-size: 2.9rem;
    font-weight: 700;
    font-family: var(--font-family);
    gap: 1rem;

    /* border: 1px solid black; */
}

.bet-infos-title>img {
    width: 3rem;
    height: 3rem;

    /* border: 1px solid black; */
}

.bet-infos-container {
    display: flex;
    flex-direction: column;

    /* border: 1px solid black; */
}

.bet-desc {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* border: 1px solid black; */
}

.bet-desc>p {
    max-width: 70%;
    font-size: 1.625rem;
    font-family: var(--font-family);
    text-align: center;
    color: var(--color-secondary);
    margin-top: 6.5rem;
}

.campaign-prizes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* width: 100%; */
    margin-top: 8rem;
    padding: 1.5rem 3rem;

    background: var(--black-05);
}

.campaign-prizes-left,
.campaign-prizes-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--color-secondary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    gap: 1rem;

    transition: all 0.1s ease-in-out;

    /* border: 1px solid black; */
}

.bet-page__logo-csleague {
    height: 4rem;
}

.campaign-prizes-left img,
.campaign-prizes-right img {
    height: 4rem;
}

.campaign-prizes-left:hover,
.campaign-prizes-right:hover {
    transform: scale(1.05);
}

.a-vos-marques {
    margin-top: 8rem;

    color: var(--color-primary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.choices-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:80%;
    flex-wrap:wrap;

    /* margin-top: 8rem;
    margin-bottom: 8rem; */
    position: relative;
    margin-top: 8rem;
    margin-bottom: 8rem;

    gap: 1rem;

    padding: 8rem 0rem;
}

.choice-container{
    flex:1;
}

.bet-page__order-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 1rem;
    margin-bottom: 8rem;
}

.pari-pris {
    font-family: var(--font-family);
    color: var(--color-secondary);
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 5rem;
}

.bet-cancel {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bet-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 4rem;
    gap: 3rem;
    font-family: var(--font-family);
    color: var(--color-primary);
    font-weight: 700;
    font-size: 1.5rem;
}

.manage-date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.user-choice {
    margin-top: -6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-choice>p {
    font-size: 3rem;
}

@media screen and (max-width:1024px) {
    .choices-container {
        gap: 0.5rem;
    }
    .bet-infos-title {
        top: -4rem;
    }
}

@media screen and (max-width:925px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 1rem;
        gap: 0.2rem;
    }

    .campaign-prizes-left img,
    .campaign-prizes-right img,
    .bet-page__logo-csleague {
        height: 2.25rem;
    }

    .campaign-prizes-left:hover,
    .campaign-prizes-right:hover {
        transform: scale(1);
    }

}

@media screen and (max-width:768px) {
    .bet-infos-title {
        margin-left: 2rem;
        top: -1rem;
        gap: 0.5rem;
    }

    .bet-infos-title>p {
        font-size: 1.75rem;
    }

    .bet-infos-title>img {
        height: 1.5rem;
        width: 1.5rem;
    }

    .bet-desc>p {
        font-size: 1.25rem;
    }

    .a-vos-marques {
        font-size: 1.6rem;
        margin-top: 6rem;
    }

    .choices-container {
        flex-direction: column;
        gap: 2rem;
    }

    .pari-pris {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:580px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 0.8rem;
        gap: 0.2rem;
    }

    .campaign-prizes-left img,
    .campaign-prizes-right img,
    .campaign-page__logo-csleague {
        height: 2rem;
    }

    .campaign-prizes {
        padding: 1.5rem 1rem;
    }
}

/* @media screen and (max-width:550px) {
    .bet-infos-title {
        margin-left: 2rem;
        top: 1.5rem;
        gap: 0.5rem;
    }

    .bet-infos-title>p {
        font-size: 1.25rem;
    }

    .bet-infos-title>img {
        top: 0rem;
        height: 1.25rem;
        width: 1.25rem;
    }
} */



@media screen and (max-width:500px) {

    .campaign-prizes-left,
    .campaign-prizes-right {
        gap: 0.1rem;
    }
}

@media screen and (max-width:425px) {
    .bet-infos-title {
        margin-left: 1rem;
        top: -0.2rem;
        gap: 0.2rem;
    }

    .bet-infos-title>p {
        font-size: 1.5rem;
    }

    .bet-infos-title>img {
        height: 1.5rem;
        width: 1.5rem;
    }

    .bet-desc>p {
        max-width: 90%;
        font-size: 1rem;
    }

    .campaign-prizes-left,
    .campaign-prizes-right {
        font-size: 0rem;
        gap: 0.2rem;
    }

    .campaign-infos-title {
        top: 0.5rem;
        margin-left: 3rem;
        font-size: 2rem;
        gap: 0.5rem;
    }

    .campaign-infos-title>img {
        width: 2rem;
        height: 2rem;
    }

    .a-vos-marques {
        font-size: 1.2rem;
    }

    .choices-container {
        padding: 6rem 0rem;
    }

    .pari-pris {
        font-size: 1rem;
    }

    .user-choice>p {
        font-size: 2rem;
    }
}

@media screen and (max-width:320px) {
    .campaign-prizes {
        padding: 1rem 0.5rem;
    }

    .campaign-infos-title {
        margin-left: 1.5rem;
    }
}