.campaign-card {
    background: black;
    transform: skew(14deg, 0deg);
    /* min-width: 22rem;
    min-height: 30rem; */
    height: 16rem;
    width: 12rem;
    z-index: 2;
    border-radius: 0.625rem;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    /* box-shadow: 2px 2px 2px rgba(87, 74, 74, 0.485); */
    box-shadow: var(--primary-shadow);
    display: inline-block;
    scroll-snap-align: center;
    flex-shrink: 0;

    /* border: 1px solid black; */
}

.campaign-card:hover {
    transform: skew(14deg, 0deg) scale(1.05);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.campaign-card-content {
    transform: skew(-14deg, 0);
    z-index: 3;
    width: 100%;
    height: 100%;
    /* height: 30rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.campaign-card-logo {
    /* max-width: 8rem;
    max-height: 8rem; */
    position: relative;
    left: -1rem;
    /* top: -1rem; */
    width: 4rem;
    height: 4rem;
    transition: all 0.3s ease-in-out;
}

.campaign-card-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.625rem;
}

.campaign-card-background>img {
    position: absolute;
    object-position: center center;
    max-width: 300%;
    max-height: 100%;
    transform: translateX(-32%) skew(-14deg, 0);
    overflow: hidden;
    object-fit: cover;

    transition: all 0.4s ease-in-out;
}

.campaign-card.selectedCard .campaign-card-background>img {
    filter: blur(4px);
}

#campaign-card-list {
    background: var(--black-05);
    padding: 8rem 4rem;
    /* min-height: 30rem;
    width: 100%; */
    overflow-x: auto;

    margin-top: 16rem;

    /* a supprimer */
    /* margin-bottom: 16rem; */

    /* border: 1px solid black; */
}

#campaign-card-list::-webkit-scrollbar {
    display: none;
}

#campaign-card-list-inner {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    position: relative;
    display: flex;
    gap: 3rem;
    justify-content: center;
}

.campaign-card-item {
    position: absolute;
}

.campaign-card-infos {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    transition: all 0.4s ease-in-out;
}

.campaign-card-name {
    text-align: end;
    transform: skew(14deg, 0);
    font-family: var(--font-family);
    padding: 0.2rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-secondary);
    background: var(--white);
    min-width: 10rem;
    margin-right: -0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 2px rgba(236, 104, 104, 0.354);
}

.campaign-card:hover .campaign-card-name {
    min-width: 12.5rem;
    transition: all 0.2s ease-in-out;
}

/* .campaign-card:hover .campaign-card-logo {
    transform: scale(1.05);
} */

.campaign-card-name>p {
    transform: skew(-14deg, 0);
}

.campaign-card:not(.selectedCard) .campaign-card-infos {
    opacity: 0;
    /* transform: scale(0); */
    transform-origin: center right;
    transition: all 0.2s ease-in-out;
}

.campaign-card:not(.selectedCard) .campaign-card-logo {
    transform: translateY(3.5rem) translateX(1rem);
}

.campaign-card:not(.selectedCard):hover .campaign-card-logo {
    transform: translateY(3.5rem) translateX(1rem) scale(1.05);
}

.campaign-card.selectedCard {
    transform: skew(14deg, 0deg) scale(1.3);
    box-shadow: var(--secondary-shadow);
}

/* .campaign-card.selectedCard .campaign-card-logo {
    transform: rotate(360deg);
} */

.campaign-card.selectedCard:hover .campaign-card-logo {
    transform: scale(1.05);
}

.campaign-card-list-button {
    position: absolute;
    top: 42rem;

    z-index: 4;
    transition: all 0.2s ease-in-out;

    display: none;
}

.next {
    right: 4rem;
}

.previous {
    left: 4rem;
}

.campaign-card-list-button>img {
    max-width: 4rem;
    max-height: 4rem;
}

.campaign-card-list-button:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.campaign-card-date {
    color: var(--white);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 0.8rem;
    margin-right: -0.3rem;
    margin-top: 0.5rem;
}

.campaign-card-partners {
    display: flex;
    flex-direction: row;
    margin-top: -1rem;
    gap: 0.5rem;

    position: relative;
    left: -1.5rem;
    top: 1rem;

    /* border: 1px solid black; */
}

.partner-logo {
    display: flex;
    height: 0rem;
    width: auto;
    transition: all 0.2s;

    border-radius: 4px;
    overflow: hidden;
}

.campaign-card.selectedCard:hover .partner-logo {
    transform: scale(1.05);
}

.campaign-card.selectedCard .partner-logo {
    height: 2rem;
}

/* .campaign-card-partners .logo-cs-league {
    border: 1px solid black;
} */

/* .campaign-card-content {
    border: 1px solid black;
} */

.dots-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 72rem;
    /* left: 40rem; */
    left: 40%;

    margin-top: 4rem;
    gap: 0.5rem;

    /* padding: 0.5rem; */
    min-height: 0.75rem;

    /* border: 1px solid black; */
}

.dot-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    width: 4rem;
    height: 0.25rem;
    border-radius: 0.5rem;
    background-color: var(--color-secondary);

    transition: all 0.1s ease-in-out;
    /* border: 1px solid black; */
}

.dot-button:hover {
    transform: scale(1.05);
    /* height: 0.5rem; */
}

/* .dot-button:hover .active-dot {
    transform: scale(1);
} */

.active-dot {
    background-color: var(--color-primary);
    transform: scale(1.1);
}

@media screen and (max-width:1342px) {
    .dots-container {
        top: 75rem;
    }
}

@media screen and (max-width:1000px) {
    .dots-container {
        top: 76rem;
    }
}

@media screen and (max-width:800px) {
    .dots-container {
        top: 64rem;
    }
}

@media screen and (max-width:770px) {
    .dots-container {
        top: 64rem;
    }
}

@media screen and (max-width:630px) {
    .dots-container {
        top: 68rem;
    }
}

@media screen and (max-width:425px) {
    .dots-container {
        top: 60rem;
    }
}


/* Pour les tailles petites d'écran on fera de toute façon un design différent. */