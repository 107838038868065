.contact-page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.navbar-container {
    display: flex;
    width: 100%;
}

.contact-page-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
    gap: 8rem;
}

.contact-page-header {
    color: var(--color-secondary);
    text-align: center;

    font-family: var(--font-family);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    width: 70%;
}

/* .contact-form {
    width: 90%;
    min-height: 32rem;
    border-radius: 1rem;

    background-color: var(--black-05);
    border: 1px solid var(--color-secondary);
    box-shadow: var(--primary-shadow);
} */

/* .contact-form p {
    color: var(--color-secondary);
    opacity: 0.75;
    font-family: var(--font-family);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
} */

.contact-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 80%;
    height: 32rem;
    gap: 1rem;
}

.contact-form textarea,
.contact-form input {
    border-radius: 1rem;
    padding: 1rem;

    font-family: var(--font-family);
    color: var(--color-secondary);

    background-color: var(--black-05);
    border: 2px solid var(--color-secondary);
    box-shadow: var(--primary-shadow);
}

#input-objet {
    height: 5%;
    width: 95%;
}

#input-message {
    height: 95%;
    width: 95%;
}

/* #input-message {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 1rem;
    margin: auto;

    font-family: var(--font-family);
    color: var(--color-secondary);

    background-color: var(--black-05);
    border: 3px solid var(--color-secondary);
    box-shadow: var(--primary-shadow);
} */

.contact-page__footer {
    display: flex;
    width: 100%;
}

@media screen and (max-width:768px) {
    .contact-page-header {
        width: 90%;
        font-size: 2rem;
    }
}

@media screen and (max-width:425px) {
    .contact-page-header {
        width: 90%;
        font-size: 1.25rem;
    }

    .contact-form {
        width: 90%;
    }
}