.betpage-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-bottom: 8rem;
    gap: 12rem;
}

.betpage-bouton-retour {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    margin-left: 4rem;
}

.betpage-header {
    color: var(--color-secondary);
    font-family: var(--font-family);
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;

    max-width: 50%;
}

.betpage-partners {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2rem;
    padding: 2rem;

    width: 100%;
    background: var(--black-05);
}

.partner-left {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    transition: all 0.1s ease-in-out;
}

.partner-left__csleague-logo {
    height: 8rem;
}

.partner-left p {
    text-align: center;
    font-family: var(--font-family);
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: var(--vertical-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: all 0.1s ease-in-out;
}

.partner-right {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    transition: all 0.1s ease-in-out;
}

.partner-right p {
    color: #000;
    text-align: center;
    font-family: var(--font-family);
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.partner-right img {
    height: 8rem;
    border-radius: 10px;
}

.partner-left:hover,
.partner-right:hover {
    transform: scale(1.05);
}

.partner-cross {
    height: 3rem;
}


.betpage-prizes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 2rem;
    gap: 2rem;

    width: 100%;
}

.prizes-title {
    color: var(--color-secondary);
    font-family: var(--font-family);
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.prizes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 4rem;
}

.prize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;
}

.prize img {
    height: 10rem;
    transition: all 0.1s ease-in-out;
}

.prize:hover img {
    transform: scale(1.05);
}

.prize p {
    color: var(--color-secondary);
    font-family: var(--font-family);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width:1150px) {
    .betpage-header {
        font-size: 2.3rem;
    }

    .prizes-container {
        gap: 2rem;
    }

    .prize img {
        height: 8rem;
    }

    .prize p {
        font-size: 1rem;
    }

    .partner-left:hover,
    .partner-right:hover {
        transform: scale(1);
    }

    .prize:hover img {
        transform: scale(1);
    }

    .partner-left p,
    .partner-right p {
        font-size: 2.4rem;
    }

    .partner-left__csleague-logo,
    .partner-right img {
        height: 6rem;
    }

    .prizes-title {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:900px) {
    .prizes-container {
        flex-direction: column;
    }
}

@media screen and (max-width:768px) {

    .partner-left__csleague-logo,
    .partner-right img {
        height: 4rem;
    }

    .partner-left p,
    .partner-right p {
        font-size: 1.8rem;
    }

    .partner-cross {
        height: 1.8rem;
    }

    .betpage-partners {
        gap: 1rem;
    }
}

/* @media screen and (max-width:425px) { */
@media screen and (max-width:768px) {
    .betpage-container {
        gap: 8rem;
    }
    .betpage-header {
        max-width: 90%;
        font-size: 2rem;
    }
    .betpage-partners {
        gap: 0.5rem;
    }
    .partner-left,
    .partner-right {
        gap: 0.5rem;
    }
    .partner-left p,
    .partner-right p {
        font-size: 1.2rem;
    }
    .partner-left__csleague-logo,
    .partner-right img {
        height: 3rem;
    }
}

/* @media screen and (max-width:375px) { */
@media screen and (max-width:768px) {
    .prize img {
        height: 6rem;
    }
}

/* @media screen and (max-width:320px) { */
@media screen and (max-width:768px) {
    .betpage-container {
        gap: 6rem;
    }
    .partner-left,
    .partner-right {
        gap: 0.2rem;
    }
    .betpage-partners {
        gap: 0.2rem;
    }
    .betpage-header {
        font-size: 1.5rem;
    }
}