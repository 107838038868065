*,
html,
body {
    margin: 0;
    padding: 0
}

html,
body {
    white-space: normal;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: var(--white);
}

.footer-container,
.navbar-container {
    display: flex;
}