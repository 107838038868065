.navbar {
    background: var(--black-05);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-left__navigation-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:2rem;
}

.navbar-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navigation-tabs__mes-paris {
    position: relative;
    top: 0.2rem;
    cursor: pointer;
}

.navigation-tabs__mes-paris h4 {
    font-family: var(--font-family);
    color: var(--color-secondary);
    font-size: 1.25rem;
    font-weight: 700;

    margin-bottom: -0.7rem;
}

.logo {
    display: flex;
    align-items: center;
    cursor: pointer;

    transition: all 0.2s;
}

.navbar-left__logo-csleague {
    width: 3rem;
}

.navbar-right__logo-instagram {
    width: 2rem;
}

.logo:hover {
    filter: drop-shadow(2px 2px 4px rgba(128, 38, 38, 0.40));
}

.navbar-right {
    display: flex;
    /* margin-right: 4rem; */
    align-items: center;
    gap: 1rem;
}

.bouton-connexion {
    cursor: pointer;
}

.userpin {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: var(--black-25);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 1.1rem;
    font-family: var(--font-family);
    color: var(--white);
}

.userpin img {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
}

.user-login {
    font-family: var(--font-family);
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 700;
    color: var(--color-secondary);
}

.bottom-line {
    /* border: 1px solid black; */
    position: relative;
    left: 50%;

    display: flex;
    flex-direction: row;

    width: 100%;

    transition: all 0.2s ease-in-out;
}

.bottom-line-left,
.bottom-line-right {
    /* border: 1px solid black; */

    display: flex;
    align-items: center;

    height: 0.0625rem;
    width: 0%;

    background-color: var(--color-secondary);

    transition: all 0.2s ease-in-out;
}

.bouton-connexion:hover .bottom-line-left,
.bouton-connexion:hover .bottom-line-right {
    width: 50%;
}

.bouton-connexion:hover .bottom-line {
    left: 0%;
}

@media screen and (max-width:768px) {
    .userpin>img {
        width: 2rem;
        height: 2rem;
    }
}

@media screen and (max-width:425px) {
    .navbar {
        padding: 1.5rem 0.5rem;
    }

    .navbar-left__logo-csleague {
        width: 2rem;
    }

    .navbar-right__logo-instagram {
        width: 1.5rem;
    }

    .userpin {
        gap: 0.5rem;
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        font-size: 0.8rem;
    }
}