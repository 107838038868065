.page-mes-paris {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* position: relative;
    left: 10%;
    width: 80%; */

    margin-top: 8rem;
    gap: 8rem;

    /* border: 1px solid black; */
}

.header-page-mes-paris {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 2rem;
    width: 100%;
    /* padding: 2rem; */
    /* background-color: var(--black-05); */

    /* border: 1px solid black; */
}

.header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    /* border: 1px solid black; */
}

.header-page-mes-paris p {

    /* width: 100%; */
    margin-left: 2rem;

    color: var(--color-secondary);
    text-align: left;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    /* border: 1px solid black; */
}

.header-info {
    /* color: var(--color-primary); */
    text-align: center;
    font-family: var(--font-family);
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: var(--horizontal-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: all 0.1s ease-in-out;

    /* border: 1px solid black; */
}

.header-info:hover {
    transform: scale(1.05);
}

.container-mes-paris {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    gap: 2rem;

    /* padding: 2rem; */

    /* border: 1px solid black; */
}

.container-mes-paris p {
    width: 100%;
    margin-left: 4rem;

    color: var(--color-secondary);
    text-align: left;
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.single-bet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;

    /* border: 2px solid black; */
}

.page-mes-paris__footer {
    display: flex;
    width: 100%;
}

@media screen and (max-width:768px) {
    .header-info {
        font-size: 2.2rem;
    }
    .header-info:hover {
        transform: scale(1);
    }
}

@media screen and (max-width:425px) {
    .header-info {
        font-size: 1.5rem;
    }
    .header-page-mes-paris p {
        font-size: 1rem;
    }
    .container-mes-paris p {
        font-size: 1rem;
    }
}