.button-classic {
    display: flex;
    align-items: center;
    gap: 0rem;

    padding: 0.8rem 3.2rem;
    background: var(--horizontal-gradient);
    border-radius: 100px;
    border: none;

    box-shadow: var(--primary-shadow);
    transition: all 0.1s;

    cursor: pointer;

    /* Heading 2 Bold */
    font-family: var(--font-family);
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    color: var(--white);
    gap: 0.5rem;
}

.button-classic svg {
    height: 2.6rem;
    width: 0rem;
    display: flex;
    transition: all 0.2s;
}

.button-classic:hover {
    transform: scale(1.025);
}

.button-classic:hover svg {
    width: 2.9rem;
}

/* Bouton retour */
.bouton-retour {
    display: flex;
    align-items: center;
    gap: 0rem;

    padding: 1rem 2rem;
    background: var(--horizontal-gradient);
    border-radius: 100px;
    border: none;

    box-shadow: var(--primary-shadow);

    cursor: pointer;

    /* Heading 2 Bold */
    font-family: var(--font-family);
    font-size: 0rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: var(--white);

    color: var(--color-secondary);

    /* a supprimer */
    /* margin-bottom: 10rem; */

    transition: all 0.1s ease-in-out;
}

.bouton-retour:hover {
    font-size: 1.8rem;
    box-shadow: var(--secondary-shadow);
}

.arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.head {
    display: flex;
    align-items: center;
}

.line {
    display: flex;
    align-items: center;

    position: relative;
    left: -1.2rem;
    top: 0.025rem;

    width: 0rem;

    transition: all 0.1s ease-in-out;
}

.bouton-retour:hover .line {
    width: 2rem;
}

.head svg {
    width: 1.5rem;
    height: auto;
}

@keyframes left-to-right {
    0% {
        position: relative;
        left: -50%;
        width: 0rem;
    }

    50% {
        position: relative;
        width: 1.45rem;
    }

    100% {
        position: relative;
        left: 0%;
        width: 2.9rem;
    }
}

.navigation-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
}

.navigation-tabs p {
    color: var(--color-secondary);

    /* Heading 4 Bold */
    font-family: var(--font-family);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bottom-line {
    position: relative;
    left: 50%;

    display: flex;
    flex-direction: row;

    width: 100%;

    transition: all 0.2s ease-in-out;
}

.bottom-line-left,
.bottom-line-right {
    display: flex;
    align-items: center;

    height: 0.0625rem;
    width: 0%;

    background-color: var(--color-secondary);

    transition: all 0.2s ease-in-out;
}

.navigation-tabs:hover .bottom-line-left,
.navigation-tabs:hover .bottom-line-right {
    width: 50%;
}

.navigation-tabs:hover .bottom-line {
    left: 0%;
}

.connexion-button-container {
    display: flex;
    font-family: var(--font-family);
    color: var(--color-secondary);
    align-items: center;
    /* gap: 1rem; */
    gap: 0rem;
    /* font-size: 1.2rem; */
    font-size: 0rem;
    padding: 1rem;
    border-radius: 5rem;
    cursor: pointer;
    background-color: transparent;

    transition: all 0.1s ease-in-out;
}

.connexion-button-container>img {
    max-width: 3rem;
    max-height: 3rem;
}

.connexion-button-container:hover {
    background-color: var(--black-05);
    gap: 1rem;
    font-size: 1.2rem;

    /* transform: scale(1.05); */
}

@media screen and (max-width:768px) {
    .connexion-button-container {
        background-color: var(--black-05);
        gap: 1rem;
        font-size: 1.2rem;
    }

    .button-classic {
        font-size: 1.5rem;
        padding: 0.5rem 1.5rem;
    }

    .button-classic svg,
    .button-classic:hover svg {
        width: 2rem;
    }

    .bouton-retour:hover {
        font-size: 0rem;
        box-shadow: var(--primary-shadow);
    }

    .bouton-retour:hover .line {
        width: 0rem;
    }

    .bouton-retour {
        padding: 0.75rem 1.5rem;
    }

    .head {
        width: 16px;
        height: 28px;
    }
}

@media screen and (max-width:425px) {
    .connexion-button-container {
        font-size: 1rem;
    }

    .bouton-retour {
        padding: 0.5rem 1rem;
    }

    .head {
        width: 12px;
        height: 21px;
    }

    .navigation-tabs>p {
        font-size: 1rem;
    }
}