.choice-container {
    /* border: 1px solid black; */

    display: flex;
    justify-content: center;
    align-items: center;

    /* margin-top: 8rem;
    margin-bottom: 8rem; */

    gap: 1rem;
}

.choice {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    width: 16rem;
    height: 22rem;

    overflow: hidden;

    box-shadow: var(--primary-shadow);
    transition: all 0.1s ease-in-out;

    border-radius: 15px;
}

.choice-background {
    position: relative;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 16rem;
}

.choice-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

/* .choice-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 1rem;

    border: 1px solid black;
} */

.choice-title {
    z-index: 4;

    position: relative;
    margin: 1rem;
    text-align: center;

    font-family: var(--font-family);
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: var(--horizontal-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: all 0.1s ease-in-out;
}

.container-bouton-parier {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    z-index: 4;

    transition: all 0.1s ease-in-out;
}

.choice:hover {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    border-radius: 15px;
    transform: scale(1.05);
}

.choice:hover .container-bouton-parier {
    position: absolute;
    top: 65%;
}

.choice:hover .choice-background {
    filter: blur(4px);
}

@media screen and (max-width:1024px) {
    .choice-container {
        gap: 0.5rem;
        /* margin-top: 6rem;
        margin-bottom: 6rem; */
    }

    .choice {
        width: 12rem;
        height: 6rem;
    }

    .choice-title {
        font-size: 1.9rem;
    }
}

@media screen and (max-width:768px) {
    .choice-container {
        gap: 2rem;
        margin-bottom: 0;
        margin-top: 0;
    }

    .choice {
        width: 16rem;
        height: 8rem;
    }

    .choice-title {
        font-size: 1.8rem;
    }
}