.bet-card {
    position: relative;
    display: flex;
    transition: all 0.1s ease-in-out;
    width: 16rem;
    height: 24rem;
}

.bet-card-background {
    position: absolute;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.bet-card-background>img {
    margin-left: -45%;
    margin-top: -150%;
    filter: blur(2px);
}

.bet-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 4;
    padding: 2rem 1rem;
    font-family: var(--font-family);
    background: rgba(150, 38, 38, 0.5);
    border-radius: 0.75rem;
    color: var(--white);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    width: 100%;
    box-shadow: var(--primary-shadow);
}

.bet-card-content>img {
    position: relative;
    top: 1rem;
    max-width: 4rem;
    max-height: 4rem;
}

.bet-card:hover .bet-card-content>img {
    top: 0rem;
    transform: rotate(360deg) scale(1.05);
    transition: all 0.4s ease-in-out;
}

.bet-card-title {
    position: relative;
    top: 3rem;
    font-size: 1.75rem;
    display: flex;

    max-width: 80%;
    height: 7rem;

    color: var(--white);
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    transition: all 0.15s ease-in-out;
}

.bet-card:hover .bet-card-title {
    top: 0rem;
    font-size: 1.25rem;
}

.bet-card-separator {
    display: flex;

    height: 4px;
    width: 0%;
    background: var(--white);
    border-radius: 2rem;

    transition: all 0.1s ease-in-out;
}

.bet-card:hover .bet-card-separator {
    height: 4px;
    width: 100%;
}

.bet-card-date {
    display: flex;
    justify-content: center;
    font-size: 0rem;
    width: 100%;

    transition: all 0.1s ease-in-out;
}

.bet-card:hover .bet-card-date {
    font-size: 1.1rem;
}

.bet-card:hover {
    transform: scale(1.05);
    transition: all 0.1s ease-in-out;
}

.bet-card:hover .bet-card-content {
    box-shadow: var(--secondary-shadow);
    transition: all 0.1s ease-in-out;
}

.bet-card-type {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}

.bet-card-type>img {
    width: 0rem;
    height: 0rem;
    transition: all 0.1s ease-in-out;
}

.bet-card:hover .bet-card-type>img {
    width: 2rem;
    height: 2rem;
}

.bet-card-type>p {
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--white);
    font-size: 0rem;

    transition: all 0.1s ease-in-out;
}

.bet-card:hover .bet-card-type>p {
    font-size: 0.8rem;
}

@media screen and (max-width:1150px) {
    .bet-card {
        width: 14rem;
    }
}

@media screen and (max-width:1024px) {
    .bet-card {
        width: 16rem;
    }

    .bet-card-title {
        max-width: 90%;
    }

    .bet-card:hover .bet-card-date {
        font-size: 1rem;
    }

    .bet-card-type {
        gap: 0.5rem;
        width: 100%;
    }
}

@media screen and (max-width:820px) {
    .bet-card {
        width: 14rem;
    }
}

@media screen and (max-width:768px) {
    .bet-card {
        width: 16rem;
    }

    .bet-card-content {
        padding: 1rem 0.5rem;
    }

    .bet-card-title {
        justify-content: center;
        max-width: 90%;
        width: 90%;
        font-size: 1.5rem;
    }

    .bet-card-date {
        font-size: 0.5rem;
    }

    /* Suppression de l'effet de hover */
    .bet-card {
        transform: scale(1);
        transition: all 0.1s ease-in-out;
    }

    .bet-card:hover {
        transform: scale(1);
    }

    .bet-card .bet-card-content>img {
        top: 0rem;
        transform: rotate(360deg) scale(1.05);
        transition: all 0.4s ease-in-out;
    }

    .bet-card .bet-card-title {
        top: 0rem;
        font-size: 1.25rem;
    }

    .bet-card .bet-card-separator {
        height: 4px;
        width: 100%;
    }

    .bet-card .bet-card-date,
    .bet-card:hover .bet-card-date {
        font-size: 1rem;
    }

    .bet-card .bet-card-content {
        box-shadow: var(--secondary-shadow);
        transition: all 0.1s ease-in-out;
    }

    .bet-card .bet-card-type>img {
        width: 2rem;
        height: 2rem;
    }

    .bet-card .bet-card-type>p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:550px) {
    .bet-card {
        width: 14rem;
    }
}

@media screen and (max-width:480px) {
    .bet-card {
        width: 12rem;
    }
}

@media screen and (max-width:425px) {
    .bet-card {
        width: 16rem;
    }
}