.mon-pari {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    width: 100%;
    height: 12rem;

    background-color: var(--black-10);
    border-radius: 15px;

    overflow: hidden;

    /* box-shadow: var(--primary-shadow); */


    /* border: 1px solid black; */
    transition: all 0.1s ease-in-out;
}

.mon-pari:hover {
    transform: scale(1.01);
}

.mon-pari:hover .bet-image>img {
    filter: blur(0px);
}

.mon-pari:hover .bet-image h1 {
    font-size: 3.2rem;
}

.mon-pari:hover .bet-image__campaign-logo {
    width: 4rem;
    height: 4rem;
}

.bet-image {
    display: flex;
    min-width: 20%;
    height: 100%;

    position: relative;

    border-right: 3px solid var(--color-primary);
}

.bet-image h1 {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
    text-align: center;

    font-family: var(--font-family);
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    transition: all 0.1s ease-in-out;
    /* border: 1px solid black; */
}

.bet-image>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(2px);
    transition: all 0.1s ease-in-out;
}

.bet-image__campaign-logo {
    display: flex;
    width: 0rem;
    height: 0rem;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);

    transition: all 0.1s ease-in-out;

    /* border: 1px solid black; */
}


.bet-title {
    color: var(--color-secondary);
    text-align: center;
    font-family: var(--font-family);
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.in-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    color: var(--color-secondary);
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.in-game h2 {
    font-size: 1.25rem;
}

.in-game h1 {
    color: var(--color-primary);
    font-size: 2rem;
}

.bouton-annuler {
    margin-right: 2rem;
    border-radius: 100px;
    transition: all 0.1s ease-in-out;
    text-align: center;
}

.bouton-annuler:hover {
    transform: scale(1.02);
}

@media screen and (max-width:768px) {
    .bet-image__campaign-logo {
        width: 4rem;
        height: 4rem;
    }

    .mon-pari:hover {
        transform: scale(1);
    }

    .mon-pari:hover .bet-image>img {
        filter: blur(2px);
    }

    .bet-image h1 {
        font-size: 2rem;
    }

    .mon-pari:hover .bet-image h1 {
        font-size: 2rem;
    }

    .bouton-annuler:hover {
        transform: scale(1);
    }

    .bet-title {
        margin-left: 2rem;
        font-size: 1.5rem;
    }

    .in-game h1 {
        font-size: 1.5rem;
    }

    .in-game h2 {
        font-size: 1rem;
    }
}

@media screen and (max-width:425px) {
    .bet-image {
        display: none;
    }

    .mon-pari {
        flex-direction: column;
        /* justify-content: center; */
        gap: 1rem;
        height: auto;
        padding: 1rem;
    }

    .bouton-annuler,
    .bet-title {
        margin: auto;
    }
}