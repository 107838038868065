.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: var(--black-05);
    padding: 1.5rem 2rem;

    margin-top: 16rem;

    width: 100%;
}

.footer-middle {
    color: var(--color-secondary);
    text-align: center;

    /* Paragraph 1 */
    font-family: var(--font-family);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    max-width: 60%;
}

.footer-right__logo-instagram {
    width: 2rem;
}

@media screen and (max-width:800px) {
    .footer-middle {
        font-size: 0.6rem;
    }
}

@media screen and (max-width:425px) {
    .footer-middle {
        display: none;
    }

    .footer {
        margin-top: 12rem;
        width: 100%;
    }

    .footer-right__logo-instagram {
        width: 1.5rem;
    }
}